import revive_payload_client_dLqaSH1PoQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._vte5crsjk3cohyvitafhz2nd6m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_DkVidoYQED from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._vte5crsjk3cohyvitafhz2nd6m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Wg5s5hRNLZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._vte5crsjk3cohyvitafhz2nd6m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_LPSiDGDHIX from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._vte5crsjk3cohyvitafhz2nd6m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6JgTYa4vNQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._vte5crsjk3cohyvitafhz2nd6m/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bLWpU4v5Ag from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._vte5crsjk3cohyvitafhz2nd6m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FkFpgfDQcp from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._vte5crsjk3cohyvitafhz2nd6m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/platform-admin/.nuxt/components.plugin.mjs";
import prefetch_client_3g9UJLA5Pe from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._vte5crsjk3cohyvitafhz2nd6m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ywJDomcNte from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.2.11_rollup@4.22.5_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import tooltip_Fr6TMWSHbm from "/vercel/path0/node_modules/.pnpm/@docue+docue-ui-v2@3.2.2_magicast@0.2.11_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16._nqzyjgqeaehglrly74js4qevia/node_modules/@docue/docue-ui-v2/ui-plugins/tooltip.ts";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/apps/platform-admin/plugins/bugsnag.ts";
export default [
  revive_payload_client_dLqaSH1PoQ,
  unhead_DkVidoYQED,
  router_Wg5s5hRNLZ,
  payload_client_LPSiDGDHIX,
  navigation_repaint_client_6JgTYa4vNQ,
  check_outdated_build_client_bLWpU4v5Ag,
  chunk_reload_client_FkFpgfDQcp,
  components_plugin_KR1HBZs4kY,
  prefetch_client_3g9UJLA5Pe,
  plugin_ywJDomcNte,
  tooltip_Fr6TMWSHbm,
  bugsnag_tkMQfOjcVZ
]