import type { Client } from '@bugsnag/js'

export default defineNuxtPlugin((nuxtApp) => {
  const { user } = useAuth()

  const bugsnag = nuxtApp.$bugsnag as Client | undefined

  bugsnag?.addOnError((event) => {
    // add user information to the error
    if (user.value)
      event.setUser(user.value.id, user.value.email)
  })
})
