<script lang="ts" setup>
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/solid'
import type { Notification } from '~/types/ui'

useHead({
  titleTemplate: title => title ? `${title} - Docue Admin` : 'Docue Admin',
})

const { notifications } = useFlashNotifications()

const getVariant = (n: Notification) => {
  switch (n.status) {
    case 'success': return 'green'
    case 'warning': return 'yellow'
  }
}
</script>

<template>
  <NuxtLayout class="antialiased">
    <div
      class="fixed top-6 flex w-full flex-col items-center justify-start gap-2"
    >
      <DTAlert
        v-for="(n, i) in notifications"
        :key="i"
        :variant="getVariant(n)"
        class="shadow-lg"
      >
        <CheckIcon
          v-if="n.status === 'success'"
          class="w-4 text-green-600"
        />
        <ExclamationTriangleIcon
          v-if="n.status === 'warning'"
          class="w-4 text-yellow-700"
        />
        {{ n.message }}
      </DTAlert>
    </div>
    <NuxtPage />
  </NuxtLayout>
</template>
