import { default as _4033PsweuTJrwMeta } from "/vercel/path0/apps/platform-admin/pages/403.vue?macro=true";
import { default as index0cNbiGMdAsMeta } from "/vercel/path0/apps/platform-admin/pages/index.vue?macro=true";
import { default as login0wa9lyuadqMeta } from "/vercel/path0/apps/platform-admin/pages/login.vue?macro=true";
import { default as indexUyD1Cq6NW3Meta } from "/vercel/path0/apps/platform-admin/pages/sales/index.vue?macro=true";
import { default as newoF2NfvrEhhMeta } from "/vercel/path0/apps/platform-admin/pages/sales/new.vue?macro=true";
export default [
  {
    name: "403",
    path: "/403",
    component: () => import("/vercel/path0/apps/platform-admin/pages/403.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/apps/platform-admin/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login0wa9lyuadqMeta || {},
    component: () => import("/vercel/path0/apps/platform-admin/pages/login.vue")
  },
  {
    name: "sales",
    path: "/sales",
    component: () => import("/vercel/path0/apps/platform-admin/pages/sales/index.vue")
  },
  {
    name: "sales-new",
    path: "/sales/new",
    component: () => import("/vercel/path0/apps/platform-admin/pages/sales/new.vue")
  }
]