import type { Notification } from '~/types/ui'

export default function () {
  const notifications = useState<Notification[]>('notifications', () => [])

  const show = (n: Omit<Notification, 'uid'>) => {
    const uid = Date.now()
    const ms = n.ms || 2000

    notifications.value.push(Object.assign(n, { uid, ms }))

    setTimeout(() => {
      notifications.value = notifications.value.filter(n => n.uid !== uid)
    }, ms)
  }

  return {
    show,
    notifications,
  }
}
