import { defu } from 'defu'
import type { UseFetchOptions } from '#app'
import type {
  Addon,
  ApiCollectionResponse,
  ApiResponse,
  CountryConfiguration,
  Coupon,
  Feature,
  IntelligenceCompany,
  NewSale,
  NewSaleInvitation,
  Partner,
  Plan,
  Sale,
  UpdateSalePayload,
  User,
  Workspace,
} from '~~/types'

type UnknownRequestBody = Record<string, unknown>
type ApiPath = `/${string}`

export default function () {
  const config = useRuntimeConfig()

  const request = <ResDataT>(url: ApiPath, options: UseFetchOptions<ResDataT> = {}) => {
    const { token } = useAuth()

    const defaults = {
      baseURL: config.public.adminApiUrl,
      headers: {
        accept: 'application/json',
        authorization: token.value ? `Bearer ${token.value}` : '',
      },
    }

    return new Promise<ResDataT>((resolve, reject) => {
      $fetch<ResDataT>(url, defu(options as object, defaults))
        .then(resolve)
        .catch((err) => {
          if (err.status === 403)
            useRouter().push('/403')

          reject(err)
        })
    })
  }

  const requestWithBody = (method: 'PUT' | 'POST' | 'PATCH') =>
    <ResDataT, ReqBodyT = UnknownRequestBody>(path: ApiPath) =>
      (body: ReqBodyT, options: UseFetchOptions<ResDataT> = {}) =>
        request<ResDataT>(path, defu(options, { method, body }) as UseFetchOptions<ResDataT>)

  const getRequest = <ResDataT>(path: ApiPath) =>
    (options: UseFetchOptions<ResDataT> = {}) =>
      request<ResDataT>(path, options)

  // const putRequest = requestWithBody('PUT')

  const postRequest = requestWithBody('POST')

  const patchRequest = requestWithBody('PATCH')

  // auth
  const auth = (googleIdToken: string) => request<{ accessToken: string }>('/auth', { method: 'POST', body: { googleIdToken } })
  const me = () => request<ApiResponse<User>>('/me')
  const logout = () => request('/logout', { method: 'POST' })

  // addons
  const getAddons = getRequest<ApiCollectionResponse<Addon>>('/v2/addons')

  // coupons
  const getCoupons = getRequest<ApiCollectionResponse<Coupon>>('/v2/coupons')

  // features
  const getFeatures = getRequest<ApiCollectionResponse<Feature>>('/v2/features')

  // plans
  const getPlans = getRequest<ApiCollectionResponse<Plan>>('/v2/plans')

  // sales
  const getSales = getRequest<ApiCollectionResponse<Sale>>('/v2/sales')
  const createSale = (s: NewSale) => postRequest<Sale, NewSale>('/v2/sales')(s)

  const updateSale = <T = UpdateSalePayload>(saleId: string, payload: T) =>
    patchRequest<ApiResponse<Sale>, T>(`/v2/sales/${saleId}`)(payload)

  const sendSaleInvitation = (saleId: string, data: NewSaleInvitation) =>
    postRequest(`/v2/sales/${saleId}/invite`)({ ...data })

  // workspaces
  const getWorkspaces = getRequest<ApiCollectionResponse<Workspace>>('/v2/workspaces')

  // patrners
  const getPartners = getRequest<ApiCollectionResponse<Partner>>('/v2/partners')

  // intelligence apis
  const searchIntelligenceCompanies = getRequest<ApiCollectionResponse<IntelligenceCompany>>('/v2/intelligence/companies/search')

  // country config
  const fetchCountryConfiguration = getRequest<{ data: CountryConfiguration }>('/v2/configurations/country')

  return {
    auth,
    me,
    logout,
    fetchCountryConfiguration,
    getSales,
    createSale,
    updateSale,
    sendSaleInvitation,
    getAddons,
    getPlans,
    getFeatures,
    getCoupons,
    getWorkspaces,
    getPartners,
    searchIntelligenceCompanies,
  }
}
