export default defineNuxtRouteMiddleware(async (to) => {
  if (to.path.includes('/login'))
    return

  const { fetchInitialData, token, tokenExpiresAt } = useAuth()
  const returnPath = `/login?redirectUrl=${to.path}`

  // Token expired
  if (tokenExpiresAt.value < Date.now())
    return returnPath

  // Try to restore session if token exists
  if (token.value) {
    try {
      await fetchInitialData()
      return
    }
    catch (er) {
      console.warn(er)
      useFlashNotifications().show({ message: 'Could not load app data', status: 'warning', ms: 10000 })
      // Clean token if request fails
      token.value = null
    }
  }

  return returnPath
})
